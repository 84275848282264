import React from 'react';
import { FaGlobe, FaLinkedin, FaTwitter } from 'react-icons/fa';
import BioModal from './modal';
import SpeakersData from "./speakers_data";

const SpeakerProfiles = () => {

    return (
        <section className='text-center  bg-LightBlue py-10'>
            {<div className='w-11/12 mx-auto'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {SpeakersData.map(speaker => (
                        <div key={speaker.id} className="p-4 bg-white rounded-lg shadow-md">
                            <img
                                src={require(`../assets/speakers/${speaker.photo}`)}
                                className="w-32 h-32 rounded-full mx-auto mb-4"
                            />
                            <h3 className="text-xl font-semibold">
                                {speaker.firstName} {speaker.lastName}
                            </h3>
                            <p className="text-sm">{speaker.title}</p>
                            <div className="flex justify-center mt-4">
                                {speaker.linkedin && (
                                    <a href={speaker.linkedin} className="mx-2" target="_blank" rel="noopener noreferrer">
                                        <FaLinkedin />
                                    </a>
                                )}
                                <BioModal speaker={speaker} />
                            </div>
                        </div>
                    ))}
                </div>
            </div> }
        </section>
    );
};

export default SpeakerProfiles;