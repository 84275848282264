import React from "react";
import CloudAI from "../assets/paths/CloudAI_Devfest.png";
import MentalHealth from "../assets/paths/MentalHealth_Devfest.png";
import Mobile from "../assets/paths/Mobile_Devfest.png";
import Software from "../assets/paths/Software_Devfest.png";

const PathsSection = () => (
  <section className="my-20">
    <h2 className="text-center pb-5">Paths</h2>
    <div class="flex flex-wrap h-auto gap-5 justify-center items-center">
      <img src={Software} class="block w-2/5" alt="Software" />
      <img src={Mobile} class="block w-2/5" alt="Mobile" />
      <img src={CloudAI} class="block w-2/5" alt="CloudAI" />
      <img src={MentalHealth} class="block w-2/5" alt="Mental Health" />
    </div>
  </section>
);

export default PathsSection;
