import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { MdClose, MdMenu } from "react-icons/md";
import { NavLink } from "react-router-dom";

var styles = {
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
  },
  bmMenu: {
    background: 'rgba(66,133,244,0.98)',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};

const HamburgerIcon = () => (
  <div className='text-white text-4xl'>
    <MdMenu />
  </div>
);

const Sidebar = () => {
  const [isMenuOpen, handleMenu] = useState(false);

  const handleCloseMenu = () => {
    handleMenu(false);
  };

  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };

  return (
    <Menu
      right
      noOverlay
      customBurgerIcon={<HamburgerIcon />}
      disableAutoFocus
      width={'35%'}
      styles={styles}
      isOpen={isMenuOpen}
      onStateChange={handleStateChange}
    >
      {/* Main container that takes full height */}
      <div className='flex flex-col justify-between h-full'>
        {/* Close icon */}
        <div className='text-white text-4xl text-right flex flex-col items-end cursor-pointer'>
          <MdClose onClick={() => handleCloseMenu()} />
        </div>

        {/* Menu items */}
        <ul className='text-right'>
          <li className='my-5'>
            <NavLink onClick={() => handleCloseMenu()} to="/" className='text-white block text-center text-sm'>Home</NavLink>
          </li>
          <li className='my-5'>
            <NavLink onClick={() => handleCloseMenu()} to='speakers' className='text-white my-2 block text-center text-sm'>Speakers</NavLink>
          </li>
        </ul>
        <div className='mt-auto flex justify-center'>
          <a
            href="https://evenea.pl/pl/wydarzenie/devfestpoznan2024"
            target="_blank"
            rel="noopener noreferrer"
            className='bg-white text-blue-600 font-bold py-2 px-4 rounded hover:bg-gray-200 transition text-center text-sm focus:outline-none focus:ring-2 focus:ring-blue-400 active:bg-gray-300'
            onClick={() => handleCloseMenu()}
          >
            Buy Ticket
          </a>
        </div>
      </div>
    </Menu>
  );
};

export default Sidebar;