import React from 'react';
import {FaGlobe, FaLinkedin, FaTwitter} from 'react-icons/fa';
import BioModal from './modal';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import SpeakersData from "./speakers_data";

const SpeakerCarousel = () => {

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return (
        <section className='text-center mt-20 bg-LightBlue py-10'>
            <h2 className='text-center'>Speakers</h2>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={4000}>
                {SpeakersData.map(speaker => (
                    <div className='w-11/14 mx-auto'>
                        <div key={speaker.id} className="p-4 bg-white rounded-lg shadow-lg m-12">
                            <img
                                src={require(`../assets/speakers/${speaker.photo}`)}
                                className="w-32 h-32 rounded-full mx-auto mb-4"
                            />
                            <h3 className="text-xl font-semibold mx-auto justify-center flex">
                                {speaker.firstName} {speaker.lastName}
                            </h3>
                            <p className="text-sm flex justify-center">{speaker.title}</p>
                            <div className="flex justify-center mt-4">
                                {speaker.linkedin && (
                                    <a href={speaker.linkedin} className="mx-2" target="_blank"
                                       rel="noopener noreferrer">
                                        <FaLinkedin/>
                                    </a>
                                )}
                                <BioModal speaker={speaker}/>
                            </div>
                        </div>
                    </div>
                ))}
            </Carousel>
        </section>);
};

export default SpeakerCarousel;
