import React from "react";
import Hero from "../components/hero.js";
import About from "../components/about.js";
import SpeakerCarousel from "../components/speakers_carousel.js";
import SponsorLogos from "../components/sponsors.js";
import LocationSection from "../components/location.js";
import PathsSection from "../components/paths.js";

const Home = () => (
  <div>
    <Hero />
    <About />
    <SpeakerCarousel />
    <LocationSection />
    <PathsSection />
    <SponsorLogos />
  </div>
);

export default Home;
