export const SpeakersData = [
    {
        id: 1,
        firstName: 'Artur',
        lastName: 'Skowroński',
        title: 'Software Engineering',
        company: 'Tech Corp',
        bio: 'Artur Skowroński - Head of Java/Kotlin Engineering at VirtusLab, he has been in the industry for ten years. During this time, he has had the opportunity to work in various roles, such as Software Engineer, Tech Lead, Architect, and even Technical Product Manager. This diverse experience enables him to approach problems from a holistic perspective.\n' +
            '\n' +
            'He still loves to get his hands dirty - for more than two years, he has been publishing weekly reviews of events in the JVM world - https://vived.substack.com/',
        photo: "artur_skowronski.jpg",
        website: 'https://johndoe.com',
        linkedin: 'https://www.linkedin.com/in/arturskowronski/',
        twitter: 'https://twitter.com/johndoe',
        isFeatured: true
    },
    {
        id: 2,
        firstName: 'Vladimir',
        lastName: 'Alekseichenko',
        title: 'Cloud&AI',
        company: 'Data Insights',
        bio: 'Vladimir is the founder and CEO of DataWorkshop, with over 10 years of hands-on experience in implementing machine learning. Before starting his own company, he served as an architect at General Electric. He specializes in solving complex business problems using artificial intelligence, ML. Vladimir advises Fortune 500 companies, helping them integrate AI/ML and LLMs across industries such as industry, automotive, telecommunications, logistics and so on.\n' +
            '\n' +
            'As the creator of the DataWorkshop community and the organizer of the DataWorkshop Club conference, he actively inspires and educates thousands of people on practical ML and LLM (production ready). He hosts the podcast "Biznes Myśli," dedicated to AI, and is the author of five courses completed by thousands of participants. His graduates work at leading companies like Google, Microsoft, Intel, IBM, Motorola, Nokia, Orange, Santander, PKO, Alior, and Revolut.\n',
        photo: 'vladimir_alekseichenko.png',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/vladimiralekseichenko/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: true
    },
    {
        id: 3,
        firstName: 'Małgorzata (Rita)',
        lastName: 'Łyczywek',
        title: 'Software Engineering',
        company: 'Data Insights',
        bio: "Head of Technology at the CODE\n" +
            "Foundation, software engineer at Wizards of the Coast, educator. She runs a tech blog, flynerd.pl, where she shares knowledge about IT and teaches programming. Mentor at numerous coding workshops for women. Last year, she was honored with the Women Who Code: 100 Technologists To Watch award.",
        photo: 'rita_luczywek.jpeg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/ritalyczywek/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: true
    },
    {
        id: 4,
        firstName: 'Dominik',
        lastName: 'Roszkowski',
        title: 'Mobile',
        company: 'Data Insights',
        bio: 'Dominik is Google Developer Expert in Flutter eager to create beautiful apps for mobile and desktop. He’s Lead Engineer at Visible and co-founder of Flutter Europe conference. He is active speaker on local meetups and writes Flutter-related articles on his blog roszkowski.dev.',
        photo: 'dominik_roszkowski.png',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/dominik-roszkowski/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: true
    },
    {
        id: 5,
        firstName: 'Jarosław',
        lastName: 'Gajewski',
        title: 'Cloud&AI',
        company: 'Data Insights',
        bio: 'Google Cloud Certified Fellow, Champion Innovator and technology enthusiast. Currently works in Eviden as Cloud Advocate responsible for the architecture of managed services for Google Cloud Platform,  Automation platforms and GenAI accelerators for development . Eviden Distinguished Expert in the area of Cloud and member of Eviden Scientific Community. Co-author of Anthos in Action book. Privately happy husband, father of 3 and board game geek.',
        photo: 'jarekgajewski.jfif',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/jaroslawgajewski/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: true
    },
    {
        id: 6,
        firstName: 'Konrad',
        lastName: 'Cłapa',
        title: 'Cloud&AI',
        company: 'Data Insights',
        bio: 'Konrad Cłapa is a Certified Google Trainer and Lead Cloud Architect working for\n' +
            'Atos R&D. He has over 14 years\' experience in the IT industry. He holds over 40 IT\n' +
            'certification. This includes all 12/12 active Google Cloud Platform certifications making\n' +
            'him the first in the world to achieve it. He is also listed among 40 individuals who hold\n' +
            'Google Cloud Certified Fellow certificate. Sharing knowledge has always been important\n' +
            'to him, so he contributes to the community by acting as a leader for a local Google Cloud\n' +
            'Developer group. Funny fact about Konrad is that till now he never failed a GCP exam\n' +
            'attempt including multiple beta exams that he has taken.\n',
        photo: 'konradcłapa.jfif',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/konrad-c%C5%82apa-google-cloud-certified-fellow-vcdx-87275bb/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: true

    },
    {
        id: 7,
        firstName: 'Artur',
        lastName: 'Wojnar',
        title: 'Software Engineering',
        company: 'Data Insights',
        bio: "I'm a software developer with 13 years of experience, but I've been interested in programming for over 20 years. Currently, I work as a hands-on Solutions Architect. My key responsibility is analyzing problem spaces and transforming them into solution spaces using different heuristics and methodologies. My work approach is always purely pragmatic and client-oriented.",
        photo: 'artur_wojnar.jpeg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/artur-wojnar-a19349a6/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 8,
        firstName: 'Konrad',
        lastName: 'Szałkowski',
        title: 'Software Engineering',
        company: 'Data Insights',
        bio: "A Java developer keen on sniffing out what's sizzling in silicon. On a daily basis, I save the world by eliminating legacy code and coffee from the machine. I'm a fan of clean code, design patterns, and the SOLID principles. I'm also a speaker at conferences and meetups, and a co-organizer of the JUG Łódź.",
        photo: 'konrad_szalkowski.jpeg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/konrad-sza%C5%82kowski-6a993431/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 9,
        firstName: 'Marcin',
        lastName: 'Milewicz',
        title: 'Software Engineering',
        company: 'Data Insights',
        bio: "A passionate programmer for over two decades. Google Developer Expert (Web Technologies), Principal Developer, Frontend Architect, and Frontend Tech Leader at Xebia Poland. In his free time, he's a Toastmaster, running enthusiast, travel and mountain lover, as well as a fan of strengths-based psychology. Strongly focused on technological innovation and personal growth. Podcaster of the 'Na Froncie' channel.",
        photo: 'marcin_milewicz.jpeg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/marcinmilewicz/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 10,
        firstName: 'Michał',
        lastName: 'Mikołajczak',
        title: 'Cloud&AI',
        company: 'Data Insights',
        bio: "A 21st-century veteran of the oil industry—data. Together with his organization, datarabbit.ai, he helps clients process and 'refine' data using analytics and AI, extracting actionable insights and enhancing products with innovative features. He has successfully designed and implemented such solutions for international clients of various scales and across a wide range of industries, particularly in the medical field.",
        photo: 'michal_mikolajczak.jpeg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/micha%C5%82-miko%C5%82ajczak/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 11,
        firstName: 'Barbara',
        lastName: 'Sobkowiak',
        title: 'Cloud&AI',
        company: 'Data Insights',
        bio: "With over 11 years of experience in data analysis, I am currently focused on machine learning and AI solutions. Throughout my career, I have also served as a data science team manager for many years, delivering end-to-end ML projects on both on-premise and cloud platforms. I bridge the gap between technology and business on projects, ensuring that they are executed in accordance with best practices and that the model supports user actions. I firmly believe that to solve clients' business problems, one must communicate with them and understand their issues before creating machine learning models. I also support the Poznan chapter of Women in Machine Learning and Data Science (WiMLDS) in its efforts to support women in this field. In my free time, I discover squash and new corners of the world, especially from the deck of a sailboat.",
        photo: 'barbara_sobkowiak.jpeg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/barbarasobkowiak/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 12,
        firstName: 'Marcin',
        lastName: 'Siudziński',
        title: 'Cloud&AI',
        company: 'Data Insights',
        bio: "A data engineer since the days when that term elicited puzzled looks, not a flood of job offers. With 15 years of IT experience, he has navigated through all the possible buzzwords: DWH, Data Lake, Big Data, and Cloud, delivering projects for both corporate giants and local heroes.\n" +
            "\n" +
            "As an authorized Google trainer, he shares his knowledge and experience during commercial training sessions and on weekends with students at Adam Mickiewicz University in Poznań.\n" +
            "\n" +
            "He has participated in the design of numerous data platforms for small and large companies, including a data warehouse for $5 (monthly) in his own company, specializing in GCP. His passion extends beyond GCP to organizational culture, DevOps, and methods such as Big Picture Event Storming and Design Thinking.\n" +
            "\n" +
            "Privately, in addition to data, he also juggles diapers as a father of four.\n",
        photo: 'michal_siudzinski.jfif',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/siudzinskim/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 13,
        firstName: 'Tobiasz',
        lastName: 'Olejnik',
        title: 'Mobile',
        company: 'Appchance',
        bio: "Mobile Team Leader at Appchance with 8 years of experience in the mobile application industry. Started as an Android developer, later transitioning to Flutter. Passionate about mentoring new talents. Enjoys traveling and playing the guitar in free time.",
        photo: 'tobiasz_olejnik.webp',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/tobiaszolejnik/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 14,
        firstName: 'Patryk',
        lastName: 'Połeć',
        title: 'Mobile',
        company: 'FutureMind',
        bio: "Passionate about the mobile world and dedicated to ensuring personal safety and privacy online. A proud Android developer with a love for both dogs and cats.",
        photo: 'patryk_polec.jpeg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/patryk-polec/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 15,
        firstName: 'Piotr',
        lastName: 'Prus',
        title: 'Mobile',
        company: '',
        bio: "Self-taught Android developer with a deep passion for tackling challenges in UI and architecture. He thrives on the opportunity to push the boundaries of what is possible in mobile app development. Currently, Piotr is enamored with Jetpack Compose and Kotlin Multiplatform Mobile, spending countless hours honing his skills and exploring their potential. Beyond his technical expertise, Piotr actively seeks to connect with new people in the industry. He is a firm believer in the power of collaboration and the value of networking. Always eager to learn from others and share his knowledge, Piotr enthusiastically engages with fellow developers, fostering meaningful connections that contribute to personal and professional growth. Piotr's knowledge and enthusiasm have earned him invitations to speak at tech conferences. He is sharing his insights and experiences on various topics around Android development. Piotr's always try to speak easy about complicated subjects. He also delivers insightful articles that provide practical tips, explore emerging trends, and delve into the nuances of Android development.",
        photo: 'piotr_prus.jpg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/piotrprus/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    },
    {
        id: 16,
        firstName: 'Jarosław',
        lastName: 'Michalik',
        title: 'Mobile',
        company: '',
        bio: "My main area of expertise is Android development. – I'm helping companies build better products. In addition to software development and consulting, I'm helping developers by giving talks, conducting workshops and mentoring. I founded KotlinTesting.com – project built to help Android/Kotlin developers navigate efficiently in the world of unit testing. Outside of tech, I play volleyball as an outside hitter and I'm having fun creating contemporary electronic music.",
        photo: 'jaroslaw_michalik.jpg',
        website: 'https://janesmith.com',
        linkedin: 'https://www.linkedin.com/in/jaroslawmichalik/',
        twitter: 'https://twitter.com/janesmith',
        isFeatured: false
    }
]

export default SpeakersData;